import { graphql, Link, PageProps } from "gatsby"
import React, { ReactElement } from "react"
import DefaultLayout from "../templates/DefaultLayout"
import {
  documentToReactComponents,
  Options,
} from "@contentful/rich-text-react-renderer"
import { BLOCKS, MARKS,INLINES } from "@contentful/rich-text-types"
import MainTextContainer from "../components/MainTextContainer/MainTextContainer"
import Seo from "../components/seo"

const options: Options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <p className="font-roboto my-4 text-base lg:text-lg ">{children}</p>
    ),
    [BLOCKS.HEADING_3]: (node, children) => (
      <h3 className="font-roboto my-2 mt-10 font-bold  text-2xl lg:text-4xl text-br-primary-blue">
        {children}
      </h3>
    ),
    [BLOCKS.UL_LIST]: (node, children) => (
      <ul className="list-disc text-base lg:text-lg ml-10">{children}</ul>
    ),
    [BLOCKS.OL_LIST]: (node, children) => (
      <ol className="list-decimal text-base lg:text-lg ml-10">{children}</ol>
    ),
    [INLINES.HYPERLINK]: (node, children) => (
      <Link to={node.data.uri} className="text-br-primary-blue font-bold">
        {children}
      </Link>
    ),
  },
}

export default function FAQ(
  props: PageProps<{
    allContentfulFrequentlyAskedQuestions: {
      nodes: [
        {
          title: string
          body: {
            raw: string
          }
        }
      ]
    }
  }>
): ReactElement {
  const document =
    props.data?.allContentfulFrequentlyAskedQuestions.nodes[0].body.raw
  return (
    <DefaultLayout>
      <Seo title="FAQ" />
      <MainTextContainer>
        {/* <h1 className="lg:text-4xl text-3xl text-br-primary-blue font-extrabold">
          {props.data?.allContentfulFrequentlyAskedQuestions.nodes[0].title}
        </h1> */}
        {documentToReactComponents(JSON.parse(document), options)}
      </MainTextContainer>
    </DefaultLayout>
  )
}

export const FAQQuery = graphql`
  query MyQuery {
    allContentfulFrequentlyAskedQuestions {
      nodes {
        title
        internal {
          content
        }
        body {
          raw
        }
      }
    }
  }
`
