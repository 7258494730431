import React, { ReactElement } from "react"
import { ParentProps } from "../Navbar/DesktopNav"

export default function MainTextContainer({
  children,
  className = "",
}: ParentProps): ReactElement {
  return (
    <main className={`max-w-[900px] mx-auto px-6 pb-20 mt-5 ${className}`}>
      {children}
    </main>
  )
}
